import React, { useState } from "react";

import * as Broker from "../../utils/broker";
import * as Analytics from "../../utils/analytics";
import CheckBox from "./checkbox";

const DARK_MODE_LIST_ID = "UdSTWi";
const DARK_MODE_NAME = "Aluminum Launch";
const INPUT_STYLES =
  "rounded w-full bg-black text-white-900 text-sm border-solid border border-white-300 px-5 py-3 placeholder-white-300 mb-5 md:py-4 md:text-lg md:mb-7";

export default function ReserveForm({ onCompleted }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [optIn, setOptIn] = useState(true);
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  function handleFirstName(e) {
    if (formErrors.firstName) {
      setFormErrors({ ...formErrors, firstName: undefined });
    }
    setFirstName(e.target.value);
  }

  function handleLastName(e) {
    setLastName(e.target.value);
  }

  function handleEmail(e) {
    if (formErrors.email) {
      setFormErrors({ ...formErrors, email: undefined });
    }
    setEmail(e.target.value);
  }

  function handleOptIn() {
    setOptIn(!optIn);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (disabled) {
      return;
    }

    const errors = {};

    if (!firstName) {
      errors.firstName = "First name is required.";
    }

    // minimal email validation
    if (email.match(/^[^@\s]+@[^@\s\.]+\.[^@\.\s]+$/) === null) {
      errors.email = "A valid email is required.";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }

    const user = {
      firstName,
      lastName,
      email,
    };

    try {
      setDisabled(true);

      const brokerListId = DARK_MODE_LIST_ID;
      const brokerFormName = DARK_MODE_NAME;

      const res = await Broker.subscribe(user, brokerListId, brokerFormName);

      if (res.status === 201) {
        Analytics.track("Lead Capture", {
          source: brokerFormName,
          marketingCommunication: optIn ? "Yes" : "No",
        });
        return onCompleted();
      }
    } catch (err) {
      console.error(err);
    }

    setDisabled(false);
    setFormErrors({
      ...formErrors,
      submitError: "Unable to submit request. Please try again.",
    });
  }

  return (
    <form
      className="mt-8 md:mt-12 pt-1 md:w-6/12 md:px-3 mx-auto lg:w-4/12"
      onSubmit={handleSubmit}
    >
      <p className="relative">
        {formErrors.firstName && (
          <span className="text-xs text-red-700 absolute left-0 -top-4">
            {formErrors.firstName}
          </span>
        )}
        <input
          className={`${INPUT_STYLES} ${
            formErrors.firstName ? "border-red-500" : "border-white-300 "
          }`}
          name="firstName"
          onChange={handleFirstName}
          placeholder="First Name*"
          type="text"
        />
      </p>

      <p className="relative">
        <input
          className={`${INPUT_STYLES} ${
            formErrors.lastName ? "border-red-500" : "border-white-300 "
          }`}
          name="lastName"
          onChange={handleLastName}
          placeholder="Last Name (Optional)"
          type="text"
        />
      </p>
      <p className="relative">
        {formErrors.email && (
          <span className="text-xs text-red-700 absolute left-0 -top-4">
            {formErrors.email}
          </span>
        )}
        <input
          className={`${INPUT_STYLES} ${
            formErrors.email ? "border-red-500" : "border-white-300"
          }`}
          name="email"
          onChange={handleEmail}
          placeholder="Email*"
          type="text"
        />
      </p>

      <p className="text-sm text-white-300 mb-8 text-left">
        <CheckBox
          label="I want to receive emails about new product releases, events, and news from Outer."
          checked={optIn}
          onCheck={handleOptIn}
        />
      </p>
      <div className="relative">
        <button
          type="submit"
          className="w-full bg-white-900 text-black text-center inline-block text-xs px-8 py-2.5 leading-6 uppercase rounded-sm font-bold sm:transition-all sm:hover:bg-opacity-80 md:text-sm md:py-5 md:text-sm"
          disabled={disabled}
        >
          Get Your Code
        </button>
        {formErrors.submitError && (
          <span className="text-xs text-red-700 absolute left-0 -top-4">
            {formErrors.submitError}
          </span>
        )}
      </div>
    </form>
  );
}
