import * as React from "react";

export default function CheckBox({ checked, onCheck, label }) {
  return (
    <label className="flex items-start mt-3 cursor-pointer">
      <input
        onChange={onCheck}
        type="checkbox"
        checked={checked}
        className="form-checkbox h-5 w-5 invisible absolute"
      />

      <span className={`w-5 h-5 mr-4 rounded flex items-center`}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_i)">
            <rect width="18" height="18" rx="3" fill="white" />
          </g>
          <rect
            x="0.5"
            y="0.5"
            width="17"
            height="17"
            rx="2.5"
            stroke="white"
          />
          <path
            d="M7.70312 11.875C7.85938 12.0312 8.125 12.0312 8.28125 11.875L12.875 7.28125C13.0312 7.125 13.0312 6.85938 12.875 6.70312L12.3125 6.14062C12.1562 5.98438 11.9062 5.98438 11.75 6.14062L8 9.89062L6.23438 8.14062C6.07812 7.98438 5.82812 7.98438 5.67188 8.14062L5.10938 8.70312C4.95312 8.85938 4.95312 9.125 5.10938 9.28125L7.70312 11.875Z"
            fill={checked ? "#000" : "#fff"}
          />
          <defs>
            <filter
              id="filter0_i"
              x="0"
              y="0"
              width="18"
              height="18"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </span>
      <span>{label}</span>
    </label>
  );
}
