import * as React from "react";
import { Link } from "gatsby";
import outerLogoSvg from "../../images/darkmode/outer-logo.svg";
import { useFunnelTracking } from "../../utils/analytics";

import "../../components/darkmode/index.scss";

import ReserveForm from "../../components/darkmode/reserve-form";

export function Logo() {
  return (
    <Link to="/darkmode" className="w-16 md:w-auto mx-auto">
      <img src={outerLogoSvg} />
    </Link>
  );
}

export function Layout({ children }) {
  const year = new Date().getFullYear();

  return (
    <div className="dark-mode-page">
      <div className="flex flex-col min-h-screen bg-black text-white-900 wire-chair-bg">
        <nav className="flex items-center pt-5 pb-10 sm:py-20">
          <Logo />
        </nav>
        {children}
        <footer className="mt-auto py-10">
          <div className="content-container-max flex flex-wrap justify-between items-center content-center">
            <p className="text-sm text-white-300 order-2 sm:order-1">
              Outer&copy; {year}
            </p>
            <p className="text-sm order-1 text-right">
              <a href="https://liveouter.com/privacy">Privacy Policy</a>
              <a className="ml-5" href="https://liveouter.com/terms">
                Terms of Use
              </a>
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default function DarkModePage() {
  useFunnelTracking("Darkmode");
  const [completed, setCompleted] = React.useState(false);

  const content = completed ? (
    <div className="content-container-max text-center transform translate-y-full welcome-fade">
      <div>
        <h3 className="text-3xl md:text-5xl lg:text-8xl leading-tight font-thin">
          Your Code Has Been Unlocked
        </h3>
        <p className="text-md md:text-2xl mt-5 lg:mt-8">
          Keep an eye on your inbox— you'll receive your code on 4/28/21.
        </p>
      </div>
    </div>
  ) : (
    <div className="content-container-max text-center">
      <div>
        <h3 className="text-3xl md:text-5xl lg:text-8xl leading-tight font-thin">
          Something Big Is Coming
        </h3>
        <p className="text-md md:text-2xl mt-5 lg:mt-8">
          Sign up to see what we've been keeping in the shadows.{" "}
          <br className="hidden sm:block" />
          Exclusive access codes will be released 4/28/21.
        </p>
      </div>
      <ReserveForm onCompleted={() => setCompleted(true)} />
    </div>
  );

  return <Layout>{content}</Layout>;
}
